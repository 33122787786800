import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TraductionService } from 'src/app/Services/traduction.service';
import {items as englishItem} from './../../../data/navbar-EN.json'
import {items as spanishItem} from './../../../data/navbar-ES.json'

@Component({
  selector: 'app-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.css'],
  animations: [
    trigger('openClose', [
      state('none', style({
        position: 'static',
      })),
      state('open', style({
        position: 'fixed',
      })),
      state('closed', style({
        position: 'fixed',
        transform: 'translateX(-20%)'
      })),

      transition('open => closed', [
        animate('400ms ease-in')
      ]),
      transition('closed => open', [
        animate('400ms ease-out')
      ]),
      transition('open => none', [
        animate('400ms ease-in')
      ]),

    ]),
  ],
})
export class NavbarMenuComponent {

  arrayLink: string[] = ['home', 'services', 'why', 'contact'];

  private language: 'ES' | 'EN' = 'ES';
  @Input() set _mobile(value: boolean) {
    this.mobile = value;
    if(!value) {
      this.isOpen = 'none'
    }
  }
  mobile: boolean = false;
  @Input() set _isOpen(value){
    if(this.mobile){
      value ? this.isOpen = 'open' : this.isOpen = 'close';
    }
  }
  isOpen: string = 'none';

  @Output() onClickLink: EventEmitter<void> = new EventEmitter<void>();

  constructor(private traductionService: TraductionService) {
    this.traductionService.languageChanged.subscribe((lang: 'ES' | 'ES') => {
      this.language = lang;
    })
  }

  getText() {
    if(this.language === 'ES') {
      return spanishItem
    } else {
      return englishItem
    }
  }

  onClick(idx: number): void {
    this.onClickLink.emit();
    if(idx === 0) {
      const scrollOptions: ScrollToOptions = {behavior: 'smooth', top: 0}
      window.scrollTo(scrollOptions)
    } else {
      let element = document.querySelector("#" + this.arrayLink[idx]);
      const scrollOptions: ScrollIntoViewOptions = {behavior: 'smooth', block: 'start'}
      element.scrollIntoView(scrollOptions);
    }
  }

}
