import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TraductionService } from 'src/app/Services/traduction.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Input() scrollOnTop: boolean = true;
  @ViewChild('checkbox', {read: ElementRef}) checkbox: ElementRef;
  
  mobile = false;
  isChecked = false;

  language = 'ES';

  ngOnInit(): void {
    this.onResize();
  }

  constructor(private traductionService: TraductionService) {
    this.traductionService.languageChanged.subscribe((lang) => {
      this.language = lang;
    })
  }

  @HostListener('window:resize')
  private onResize(): void {
    window.innerWidth < 900 ? this.mobile = true : this.mobile = false;
  }

  checkValue(event: any): void {
    this.isChecked = event.target.checked;
  }

  languageChange(): void {
    this.traductionService.changeLanguage();
  }

  onClickLink(): void {
    this.checkbox.nativeElement.checked = false;
    this.isChecked = false;
  }

}
