<div class="why-container">
    <div class="why-title title">
        <h2>
            {{getText().title}}
        </h2>
        <app-side-bar></app-side-bar>
    </div>
    <div class="why-description subtitle">
        <h4 class="why-subtitle">
            {{getText().body1}}
        </h4>
    </div>
    <div class="why-description subtitle">
        <h4 class="why-subtitle">
            {{getText().body2}}
        </h4>
    </div>
    <div class="why-description subtitle">
        <h4 class="why-subtitle">
            {{getText().body3}}
        </h4>
    </div>
</div>