import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NavbarComponent } from './Components/navbar/navbar.component';
import { NavbarMenuComponent } from './Components/navbar-menu/navbar-menu.component';
import { CarouselComponent } from './Components/carousel/carousel.component';
import { RoundedButtonComponent } from './Components/rounded-button/rounded-button.component';
import { LayoutContainerComponent } from './Components/layout-container/layout-container.component';

import { HttpClientModule } from '@angular/common/http';
import { OurProductsComponent } from './Components/our-products/our-products.component';
import { WhyUsComponent } from './Components/why-us/why-us.component';
import { FooterBarComponent } from './Components/footer-bar/footer-bar.component';
import { ContactComponent } from './Components/contact/contact.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SideBarComponent } from './Components/side-bar/side-bar.component';
import { CarouselItemComponent } from './Components/carousel-item/carousel-item.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    NavbarMenuComponent,
    CarouselComponent,
    RoundedButtonComponent,
    LayoutContainerComponent,
    OurProductsComponent,
    WhyUsComponent,
    FooterBarComponent,
    ContactComponent,
    SideBarComponent,
    CarouselItemComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
