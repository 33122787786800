<div [@openClose]="isOpen">
    <ul class="nav__menu">
        <li class="mobile-container">
            <img class="nav__menu-vertical-img" src="./../../../assets/nicpetrol-banner.png">
        </li>
        <li *ngFor="let li of getText(), index as idx">
            <a class="clickeable-item" (click)="onClick(idx)">{{li}}</a>
        </li>
    </ul>
</div>
<div class="opacity-span mobile-container">
</div>