import { AfterViewInit, Component, HostListener } from '@angular/core';
import { LayoutContainerComponent } from './Components/layout-container/layout-container.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'nicpetrol';

  ngAfterViewInit(): void {
    this.onScroll()
  }

  scrollOnTop: boolean = true;
  @HostListener('window:scroll')
    onScroll(): void {
        window.scrollY == 0 ? this.scrollOnTop = true : this.scrollOnTop = false;
    }
}
