import { Component, HostListener } from '@angular/core';
import { TraductionService } from 'src/app/Services/traduction.service';
import espBody from '../../../data/contact-ES.json'
import engBody from '../../../data/contact-EN.json'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {

  private language: 'ES' | 'EN' = 'ES';
  formWidth = 540;

  ngOnInit(): void {
    this.onResize();
  }

  @HostListener('window:resize')
  private onResize(): void {
    window.innerWidth < 900 ? this.formWidth = 340 : this.formWidth = 540;
  }

  constructor(private traductionService: TraductionService) {
    this.traductionService.languageChanged.subscribe((lang: 'ES' | 'ES') => {
      this.language = lang;
    })
  }

  getText() {
    if(this.language === 'ES') {
      return espBody
    } else {
      return engBody
    }
  }

}
