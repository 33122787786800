import { Component, ElementRef, HostListener, ViewChildren } from '@angular/core';
import { TraductionService } from 'src/app/Services/traduction.service';
import espBody from '../../../data/why-ES.json'
import engBody from '../../../data/why-EN.json'

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.scss']
})
export class WhyUsComponent {

  @ViewChildren('gradient') gradients;

  private language: 'ES' | 'EN' = 'ES';

  showed: boolean = false;

  constructor(private traductionService: TraductionService, private element: ElementRef) {
    this.traductionService.languageChanged.subscribe((lang: 'ES' | 'ES') => {
      this.language = lang;
    })
  }

  @HostListener('window:scroll')
  onScroll(): void {
    if(window.scrollY >= this.element.nativeElement.offsetTop - this.element.nativeElement.offsetHeight - 20) {
      setTimeout(() => {
        this.showed = true;
        this.generateIncrement();
      }, 1000)
    }
  }

  getText() {
    if(this.language === 'ES') {
      return espBody
    } else {
      return engBody
    }
  }

  realValues: number[] = [1000,2000,3000,1000];
  values: number[] = [0,0,0,0];
  generateIncrement(): void {
    this.values.forEach( (val, idx) => {
      const interval = setInterval(()=>{
        if(this.values[idx] < Number(this.realValues[idx])){
          this.values[idx] = this.values[idx] + (this.realValues[idx]/1000);
        } else {
          clearInterval(interval)
        }
      }, 15)
    })
  }
  resetValue(): void {
    this.values = [0,0,0,0];
  }

}
