import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TraductionService {

  languageChanged = new BehaviorSubject('ES');
  private language: 'ES' | 'EN' = 'ES';

  getLanguage(): string {
    return this.language;
  }

  changeLanguage(): void {
    switch(this.language){
      case 'EN': {
        this.language = 'ES'
        this.languageChanged.next('ES')
        break;
      }
      case 'ES': {
        this.language = 'EN'
        this.languageChanged.next('EN')
        break;
      }
    }
  }

}
