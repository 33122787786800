import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-rounded-button',
  templateUrl: './rounded-button.component.html',
  styleUrls: ['./rounded-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundedButtonComponent {
  backgroundStyle: any = {};
  backgroundNormalStyle: any = {};
  backgroundHoverStyle: any = {};
  @Input() src: string = '';

  @Input() set background(color: string) {
    this.backgroundNormalStyle = {'background-color' : color};
    this.backgroundStyle = this.backgroundNormalStyle;
  }
  @Input() set backgroundHover(color: string) {
    this.backgroundHoverStyle = {'background-color' : color};
  }

  overRoundedButton(): void {
    this.backgroundStyle = this.backgroundHoverStyle;
  }

  leaveRoundedButton(): void {
    this.backgroundStyle = this.backgroundNormalStyle;
  }
}
