import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0,
      })),

      transition('open => closed', [
        animate('1.5s'),
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),

    ]),
  ],
})
export class LayoutContainerComponent {

  isOpen = false;
  @ViewChild('layoutContainer', {read: ElementRef} ) layout: ElementRef;

  constructor( private cdr: ChangeDetectorRef ) {}

  ngAfterViewInit(): void {
    this.onScroll();
  }

  @HostListener('window:scroll')
    onScroll(): void {
      if(window.scrollY + window.innerHeight * 0.50 > this.layout.nativeElement.offsetTop) {
        this.isOpen = true
        this.cdr.detectChanges();
      }
    }

}
