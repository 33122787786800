import { Component, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import EmblaCarousel, { EmblaCarouselType } from 'embla-carousel';
import { OptionsType } from 'embla-carousel/components/Options';

interface ImagePackage {
  src: string;
  srcActivated: string;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  imagePaths: ImagePackage[] = [
    {
      src: './../../../assets/carousel/1.jpg',
      srcActivated: './../../../assets/carousel-activated/1.jpg',
    },
    {
      src: './../../../assets/carousel/3.jpg',
      srcActivated: './../../../assets/carousel-activated/3.jpg',
    },
    {
      src: './../../../assets/carousel/6.jpg',
      srcActivated: './../../../assets/carousel-activated/6.jpg',
    },
    {
      src: './../../../assets/carousel/7.jpg',
      srcActivated: './../../../assets/carousel-activated/7.jpg',
    },
    {
      src: './../../../assets/carousel/9.jpg',
      srcActivated: './../../../assets/carousel-activated/9.jpg',
    },
    {
      src: './../../../assets/carousel/13.jpg',
      srcActivated: './../../../assets/carousel-activated/13.jpg',
    },
    {
      src: './../../../assets/carousel/17.jpg',
      srcActivated: './../../../assets/carousel-activated/17.jpg',
    },
  ]

  embla: EmblaCarouselType;
  index: number = 0;

  topButtons: string = '300px';

  @ViewChild('track', { static: true }) protected track: ElementRef<any>;

  ngOnInit() {
    const options: Partial<OptionsType> = {
      dragFree: false,
    };
    this.embla = EmblaCarousel(this.track.nativeElement, options);
    this.embla.on('init', () => {
      setTimeout(()=>{
        this.onResize();
      },1500)
    });
    this.embla.on('select', (event) => {
      this.index = this.embla.selectedScrollSnap();
      this.setEnabledTransitions();
    });
  }

  @HostListener('window:resize')
  private onResize(): void {
    this.topButtons = (-1 * this.track.nativeElement.clientHeight / 2 - 10).toString() + 'px'
  }

  setEnabledTransitions(): void {
    this.enabledNext = this.embla.canScrollNext()
    this.enabledPrev = this.embla.canScrollPrev();
  }

  enabledNext: boolean = true;
  enabledPrev: boolean = false;

  onClickNext(){
    this.embla.scrollNext();
  }

  onClickPrev(){
    this.embla.scrollPrev();
  }

}

