<div class="contact-container">

    <div class="contact-column">
        <div class="title">
            <h2>
                {{getText().title}}
            </h2>
        </div>
        <div class="subtitle">
            <h3>
                {{getText().desc}}
            </h3>
        </div>
        <div class="contact-item" *ngFor="let channel of getText().channels">
            <p class="contact-item-title">
                {{channel.title}}
            </p>
            <p class="contact-item-desc">
                {{channel.desc}}
            </p>
        </div>
    </div>

    <div class="contact-column google-form">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfgL73rXR25PmQ0MsDXmdBNdKpLw0Cfjaofg1HW0-Al-ErvcA/viewform?embedded=true" [width]="formWidth" height="800" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
    </div>

</div>