<nav>
    <div class="nav" [ngClass]="{'nav--compressed': !scrollOnTop}">
        <div class="nav__left-side">
            <img class="menu__logo" src="./../../../assets/nicpetrol-banner.png">
        </div>
    
        <div class="nav__right-side">
            <div class="menu__language clickeable-item" (click)="languageChange()">
                <i class="uil uil-globe"></i>
                <span>{{language}}</span>
            </div>
            <div *ngIf="!mobile" >
                <app-navbar-menu [mobile]="mobile"></app-navbar-menu>
            </div>
            <div *ngIf="mobile" class="left__mobile big-size">
                <input id="checkbox" #checkbox type="checkbox" [(ngModel)]="isChecked" (change)="checkValue($event)" id="nav__checkbox" class="nav__checkbox"/>
                <label for="nav__checkbox" class="nav__toggle clickeable-item">
                    <i class="uil uil-bars menu"></i>
                    <i class="uil uil-times close"></i>
                </label>
            </div>
        </div>
    </div>
</nav>
<div *ngIf="mobile && isChecked" class="vertical">
    <app-navbar-menu [_mobile]="mobile" [_isOpen]="isChecked" (onClickLink)="onClickLink()">
        <i class="uil uil-times close"></i>
    </app-navbar-menu>
</div>