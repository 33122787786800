<div class="products-container">
    <div class="products">
        <div class="product-title title">
            <h2>
                {{getText().title}}
            </h2>
            <app-side-bar></app-side-bar>
        </div>
        <div class="items-container">
            <div *ngFor="let item of getText().items" class="product-item-container">
                <div class="product-item">
                    <div>
                        <div class="title">
                            <h3 class="item-title">
                                {{item.title}}
                            </h3>
                        </div>
                        <div class="title">
                            <h4 class="item-title">
                                {{item.subtitle}}
                            </h4>
                        </div>
                    </div>
                    <div>
                        <p class="item-description-paragraph paragraph">
                            {{item.description}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>