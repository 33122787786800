<app-layout-container>
    <app-navbar [scrollOnTop]="scrollOnTop"></app-navbar>
</app-layout-container>
<!-- <div class="nav" [ngClass]="{'nav--compressed': !scrollOnTop}"></div> -->

<app-layout-container>
    <app-carousel></app-carousel>
</app-layout-container>

<app-layout-container>
    <app-our-products id="services"></app-our-products>
</app-layout-container>

<app-layout-container>
    <app-why-us id="why"></app-why-us>
</app-layout-container>

<app-layout-container>
    <app-contact id="contact"></app-contact>
    <app-footer-bar></app-footer-bar>
</app-layout-container>

<app-rounded-button background="#4dc247" backgroundHover="#44AA3E" src="./../assets/whatsapp.png">
</app-rounded-button>
