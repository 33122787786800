import { Component, OnInit } from '@angular/core';
import { TraductionService } from 'src/app/Services/traduction.service';
import espBody from '../../../data/services-ES.json'
import engBody from '../../../data/services-EN.json'

@Component({
  selector: 'app-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.css']
})
export class OurProductsComponent {

  private language: 'ES' | 'EN' = 'ES';

  constructor(private traductionService: TraductionService) {
    this.traductionService.languageChanged.subscribe((lang: 'ES' | 'ES') => {
      this.language = lang;
    })
  }

  getText() {
    if(this.language === 'ES') {
      return espBody
    } else {
      return engBody
    }
  }

}
